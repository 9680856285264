import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en_us: {
    translation: {
      offline1: `Offline Mode allows users to save selected data collections to their device. This data will be used to pre-populate form fields, allow data collection fitltering, and the viewing of complete data collection sets.`,
      offline2: `All data is remove from your device when you logout, for security reasons.`,
      offline3: `Any non-selected collections will be removed from your device's storage upon sync.`,
      offline4: `Please select the desired data you wish to save to your device.`,
      sdsSearchPlaceholder: `Search By: Title, Manufacturer, Product Code`,
      sdsDisabled1: `Access to our Safety Data Sheets engine (see example below)`,
      sdsDisabled2: `Easily keep track of relevant SDS and make them available to all remote workers`,
      sdsDisabled3: `Customize the database to manufacturer’s specific to your company`,
      noFileAttachmentDownload: `Sorry. This file attachment cannot be downloaded at this time.`,
      uploadError: `Upload Error! View report in Settings`,
      uploadCancelled: `CSV File Upload Cancelled`,
      uploadComplete: `CSV File Uploaded and sent to SafetyHQ support staff.`,
      missingRequiredCaps: `Sorry, but you do are missing the required capabilities`,
      noFormSubmissionOffline: `Form cannot be submitted offline.`,
      saveFormDraftOffline: `Offline. Draft has been saved.`,
      passwordError: `Must contain uppercase & lowercase letters plus a number`,
      error: `Sorry. There was an error. Try Again.`,
      notFoundAlert: `Sorry. This {{type}} could not be found.`,
    },
  },
  es_mx: {
    translation: {
      notFoundAlert: `Lo sentimos. Este {{type}} no se pudo encontrar.`,
      'Fetching Form': `Buscando Formulario`,
      Max: `Máximo`,
      'Select Company': `Seleccionar Empresa`,
      Breakdown: `Desglose`,
      'is required': `es requerido`,
      'Add New': `Añadir Nuevo`,
      'Additional Information': `Información Adicional`,
      Investigation: `Investigación`,
      Unauthorized: `No Autoizado`,
      'Missing Capabilities': `Capacidades Faltantes`,
      'Form Info': `Info del Formulario`,
      'Device Info': `Info del Dispositivo`,
      'Reports cannot be deleted offline': `Los informes no se pueden eliminar sin conexión`,
      'Delete Bug Report?': `¿Eliminar informe de errores?`,
      Previous: `Previo`,
      Next: `Próximo`,
      error: `Lo siento. Hubo un error. Intentar otra vez.`,
      'This Week': `Esta Semana`,
      'This Month': `Este Mes`,
      'Last 6 Months': `Últimos 6 Meses`,
      'This Year': `Este Año`,
      'Reports cannot be submitted offline': `Los informes no se pueden enviar sin conexión`,
      Total: `Total`,
      'Deficiency Types': `Tipos de Deficiencia`,
      'Delete Incident Report': `Eliminar Informe de Incidente`,
      'Deficiency Type': `Tipo de Deficiencia`,
      'Deficiency Count by Severity': `Recuento de Deficiencias por Gravedad`,
      'Deficiency Count Over Time': `Recuento de Deficiencias a lo Largo del Tiempo`,
      Time: `Hora`,
      "Thanks, we're looking into this.": `Gracias, estamos investigando esto.`,
      Daily: `Diario`,
      Weekly: `Semanalmente`,
      Count: `Contar`,
      'Edit Corrective Action': `Editar Acción Correctiva`,
      noFormSubmissionOffline: `El formulario no se puede enviar sin conexión`,
      saveFormDraftOffline: `Desconectado. Se ha guardado el borrador`,
      'Hazard Severity': `Severidad del Peligro`,
      'Edit Equipment': `Editar Equipo`,
      'In Service Date': `Fecha de servicio`,
      'Edit Certification': `Editar Certificación`,
      'Project Name': `Nombre del Proyecto`,
      'Project Number': `Número de Proyecto`,
      'Certification Created': `Certificación Creada`,
      'Certification Share': `Cuota de Certificación`,
      'Logout?': `¿Cerrar sesión?`,
      'Complete Corrective Action': `¿Acción correctiva completa?`,
      'Corrective Action Completed': `Acción correctiva completada`,
      Back: `Espalda`,
      Login: `Acceso`,
      'Action Taken': `Acción Tomada`,
      'Action to Take': `Acción a Tomar`,
      'Action Required': `Acción Requerida`,
      'You are not authorized to view the contents of this page.': `No está autorizado para ver el contenido de esta página.`,
      Deficiency: `Deficiencia`,
      'Date Created': `Fecha de Creacion`,
      'Create Toolbox Talk': `Crear Charla de Caja de Herramientas`,
      'Edit Toolbox Talk': `Charla de Caja de Herramientas de Edición`,
      'Recorded Talks': `Charlas Grabadas`,
      'Conduct Talk': `Conducir Charla`,
      Translate: `Traducir`,
      'Mark Completed': `Marcar Completado`,
      'This corrective action could not be found.': `No se pudo encontrar esta acción correctiva.`,
      'View Entry': `Ver Entrada`,
      'This Toolbox Talk could not be found.': `No se pudo encontrar este Toolbox Talk.`,
      'First Name': `Primer Nombre`,
      'Last Name': `Apellido`,
      'Not Found': `Extraviado`,
      "You weren't supposed to see this.": `No se suponía que vieras esto.`,
      "The page you're looking for appears to have been moved, renamed or deleted. Use the back button below.": `La página que está buscando parece haber sido movida, renombrada o eliminada. Utilice el botón Atrás a continuación.`,
      'Head Back to SafetyHQ': `Regresar a SafetyHQ`,
      Email: `Email`,
      Password: `Contraseña`,
      'Confirm Password': `Confirmar Contraseña`,
      Role: `Papel`,
      passwordError: `Debe contener letras mayúsculas y minúsculas más un número`,
      'Edit Worker': `Editar Trabajador`,
      Yes: `Sí`,
      'Not Active': `No Activo`,
      No: `No`,
      Spanish: `Español`,
      English: `Inglés`,
      'Employee ID': `ID de Empleado`,
      'Phone Number': `Número de Teléfono`,
      'Vaccine Status': `Estado de la Vacuna`,
      'Worker Created': `Trabajador Creado`,
      'Create Worker': `Crear Trabajador`,
      Address: `Dirección`,
      'Request SDS': `Solicitar SDS`,
      Content: `Contenido`,
      "Manufacturer's Website": `Sitio Web del Fabricante`,
      Product: `Producto`,
      'Street Address': `Dirección`,
      'Address Line 2': `Dirección Línea 2`,
      City: `Ciudad`,
      Country: `País`,
      'State / Province': `Estado o Provincia`,
      'ZIP / Postal Code': `CP o Código Postal`,
      'Hospital Name': `Nombre del Hospital`,
      'Hospital Addres': `Dirección del Hospital`,
      'Project Created': `Proyecto Creado`,
      'Create Project': `Crear Proyecto`,
      'Edit Project': `Editar Proyecto`,
      'Close Form': `Cerrar Formulario`,
      'Create Certification': `Crear Certificación`,
      'Exit?': `¿Salida?`,
      'Save before exiting?': `¿Guardar antes de salir?`,
      'Draft entry discarded': `Borrador de entrada descartado`,
      'Account Settings': `Configuraciones de la Cuenta`,
      'Activate offline mode: Workers Collection': `Activar modo sin conexión: Colección de trabajadores`,
      'All results have been loaded': `Todos los resultados han sido cargados`,
      'All Results Loaded': `Todos los Resultados Cargados`,
      'All Toolbox Talks Loaded': `Todas las Charlas de Caja de Herramientas Cargadas`,
      'Allow Location': `Permitir Ubicación`,
      'Assign Lesson To Workers': `Asignar Lección a Los Trabajadores`,
      'Assign Lesson': `Asignar Lección`,
      'Assigned To': `Asignado A`,
      'Assigned Workers': `Trabajadores Asignados`,
      'Back to List': `Volver a la Lista`,
      'Begin Quiz': `Comenzar la Prueba`,
      'Certified Worker': `Trabajador Certificado`,
      'Clear Drafts': `Borradores Claros`,
      'Clear Error Reports': `Borrar Informes de Errores`,
      'Clear Filters': `Borrar Filtros`,
      'Contact us via email or phone': `Contáctenos por correo electrónico o por teléfono`,
      'Corrective Action Photo': `Foto de Acción Correctiva`,
      'Corrective Action Reports': `Informes de Acciones Correctivas`,
      'Corrective Action Status': `Estado de la Acción Correctiva`,
      'Corrective Actions': `Acciones Correctivas`,
      'Current Month': `Mes Actual`,
      'Current Week': `Semana Actual`,
      'Custom Date Range': `Intervalo De Fechas Personalizado`,
      'Danger Zone': `Zona Peligrosa`,
      'Date Completed': `Fecha de Finalización`,
      'Date From': `Fecha de`,
      'Date Range': `Rango de Fechas`,
      'Date Taken': `Fecha que se Tendrá`,
      'Date To': `Fecha Hasta`,
      'Date Uploaded': `Fecha de Carga`,
      'days overdue': `días de retraso`,
      'days to expiry': `días para el vencimiento`,
      'De-Activate Offline Mode': `Desactivar el Modo Sin Conexión`,
      'Deficiency Owners': `Dueños de Deficiencia`,
      'Delete All Drafts': `Eliminar Todos Los Borradores`,
      'Delete All Reports': `Eliminar Todos Los Informes`,
      'Delete Certification': `Eliminar Certificación`,
      'Delete Equipment': `Eliminar Equipo`,
      'Delete Photo': `Borrar Foto`,
      'Delete selected draft entries': `Eliminar entradas de borrador seleccionadas`,
      'Delete selected draft entry': `Eliminar la entrada de borrador seleccionada`,
      'Delete Stored Data': `Eliminar Datos Almacenados`,
      'Delete Successful': `Eliminar Con Éxito`,
      'Delete Worker': `Eliminar Trabajador`,
      'Delete Zone': `Borrar Zona`,
      'Document Tags': `Etiquetas de Documentos`,
      'Draft Settings': `Configuración de Borrador`,
      'Due Date From': `Fecha de Vencimiento Desde`,
      'Due Date To': `Fecha de Vencimiento Para`,
      'Due Date': `Fecha de Vencimiento`,
      'Employee Id': `ID de Empleado`,
      'End Date': `Fecha Final`,
      'Equipment Search': `Búsqueda de Equipos`,
      'Equipment Status': `Estado del Equipo`,
      'Equipment Tags': `Etiquetas de Equipo`,
      'Error Deleting Device Data': `Error al Eliminar los Datos Del Dispositivo`,
      'Error Reporting': `Error al Reportar`,
      'Error. Try again.': `Error. Intentar Otra Vez.`,
      'Expand Draft Settings': `Expandir configuración de borrador`,
      'Expand Error Reporting': `Expandir Informe de Errores`,
      'Expand Offline Mode': `Expandir el Modo Fuera de Línea`,
      'Expand Preferences': `Ampliar Preferencias`,
      'Expires in 30 days': `Vence en 30 Dias`,
      'Expires in 60 days': `Vence en 60 Dias`,
      'Expires in 90 days': `Vence en 90 Dias`,
      'Expiry Date': `Fecha de Caducidad`,
      'Form Rule Selection': `Selección de Reglas de Formulario`,
      'Form Submission Graph': `Gráfico de Envío de Formularios`,
      'Hospital Address': `Dirección del Hospital`,
      'Inspection From Date': `Inspección Desde la Fecha`,
      'Inspection Interval': `Intervalo de Inspección`,
      'Inspection To Date': `Inspección Hasta la Fecha`,
      'Key Personnel': `Personal Clave`,
      'Language Selection': `Selección de Idioma`,
      'Last 3 Months': `Últimos 3 meses`,
      'Last 4 Weeks': `Últimas 4 Semanas`,
      'Last Inspected': `Última Inspección`,
      Relationships: `Relaciones`,
      'Assigned Worker': `Trabajador Asignado`,
      'Equipment Created': `Equipo Creado`,
      'Create Equipment': `Crear Equipo`,
      'Last Month': `El Mes Pasado`,
      'Last Sourced': `Última Fuente`,
      'Last Week': `La Semana Pasada`,
      'Last Year': `El Año Pasado`,
      'Lesson assignment sent': `Asignación de lección enviada`,
      'Lesson Categories': `Categorías de Lecciones`,
      'Lesson Difficulties': `Dificultades de la Lección`,
      'Lesson Documents': `Documentos de la Lección`,
      'Location Settings': `Configuración de Ubicación`,
      'Manager Search': `Búsqueda de Gerente`,
      'Mark all read': `Haz que todos lean`,
      'Mark Read': `Hacer Leer`,
      'Mark Unread': `Hacer no Leído`,
      'Next Inspection Date': `Próxima Fecha de Inspección`,
      'Sign Here': `Firma Aqui`,
      'Assigned to Equipment': `Asignado al Equipo`,
      'No Draft Entries to remove': `No Hay Entradas de Borrador Para Eliminar`,
      'No form rules set': `No se ha establecido ninguna regla de formulario`,
      'No Managers recipients': `No hay administradores de destinatarios`,
      'No results found': `No se han encontrado resultados`,
      'Non-Compliant': `No Conforme`,
      'Not Started': `No Empezado`,
      'Notification sent': `Notificación enviada`,
      'Notify Managers': `Notificar a los Gerentes`,
      'Notify Workers': `Notificar a los Trabajadores`,
      'Offline Mode': `Modo Offline`,
      'Overall Compliance': `Cumplimiento General`,
      'Please contact your SafetyHQ Custom Success Representative for information about setting form submission rules for compliance reporting.': `Comuníquese con su representante de SafetyHQ Custom Success para obtener información sobre cómo establecer reglas de envío de formularios para informes de cumplimiento`,
      'Plus a whole lot more!': `¡Mas, un Monton Mas!`,
      'Project Address': `Dirección del Proyecto`,
      'Project Status': `Estado del proyecto`,
      'Project Type': `Tipo de Proyecto`,
      'Project Types': `Tipos de Proyectos`,
      'Request Manufacturer': `Solicitar Fabricante`,
      'row selected': `fila seleccionada`,
      'rows selected': `filas seleccionadas`,
      'SDS Search': `Búsqueda de SDS`,
      'Serial Number': `Número de Serie`,
      'Set Filters': `Establecer Filtros`,
      'Set Translation': `Establecer Traducción`,
      'Share Certifications': `Compartir Certificaciones`,
      'Show Less': `Muestra Menos`,
      'Show More': `Mostrar Más`,
      'Start Date': `Fecha de Inicio`,
      'Submitted By': `Presentado Por`,
      'Sync Collections': `Sincronizar Colecciones`,
      'This Certification could not be found': `No se Pudo Encontrar Esta Certificación`,
      'This Equipment could not be found': `No se pudo encontrar este equipo`,
      'This is a Premium Feature': `Esta es una Función Premium`,
      'This project could not be found': `Este proyecto no se pudo encontrar`,
      'Toolbox Talk Category': `Categoría de Conversación de Caja de Herramientas`,
      'Toolbox Talk': `Charlas de caja de Herramientas`,
      'Toolbox Talks': `Charlas de caja de Herramientas`,
      'Training Type': `Tipo de Entrenamiento`,
      'Training Types': `Tipos de Entrenamiento`,
      'Unlock Now': `Desbloquear Ahora`,
      'Upload Projects': `Subir Proyectos`,
      'Upload Workers': `Subir Trabajadores`,
      'Uploaded By': `Subido Por`,
      'Vaccination Status Download': `Descarga del Estado de Vacunación`,
      'Vaccine Staus': `Estado de la Vacuna`,
      'View More': `Ver Más`,
      'Want to Get More Out of SafetyHQ?': `¿Quiere sacar más provecho del arnés?`,
      'Worker Certifications': `Certificaciones de Trabajadores`,
      'Worker Compliance': `Cumplimiento del Trabajador`,
      'Worker Language': `Idioma del Trabajador`,
      'Worker role does not match any available form rules': `El rol de trabajador no coincide con ninguna regla de formulario disponible`,
      'Worker Roles': `Papel del Trabajador`,
      'Worker Roster Request': `Solicitud de Lista de Trabajadores`,
      'Worker Status': `Estatus de Trabajador`,
      'Worker Submission Graph': `Gráfico de Presentación de Trabajadores`,
      'Year to Date': `El Año Hasta la Fecha`,
      'You have no notifications!': `¡No tienes notificaciones!`,
      Action: `Acción`,
      Active: `Activo`,
      Apply: `Solicitar`,
      Assigned: `Asignado`,
      Categories: `Categorías`,
      Category: `Categoría`,
      Certification: `Certificación`,
      Certifications: `Certificaciones`,
      'Change Password': `Cambia la Contraseña`,
      Close: `Cerca`,
      Clear: `Claro`,
      missingRequiredCaps: `Lo sentimos, pero le faltan las capacidades requeridas`,
      Companies: `Compañías`,
      Company: `Empresa`,
      Completed: `Terminado`,
      Compliant: `Obediente`,
      Confirm: `Confirmar`,
      Create: `Crear`,
      Created: `Creado`,
      Customer: `Cliente`,
      Date: `Fecha`,
      Delete: `Eliminar`,
      Division: `División`,
      Divisions: `Divisiones`,
      Document: `Documento`,
      Documents: `Documentos`,
      Download: `Descargar`,
      Drafts: `Borradores`,
      Edit: `Editar`,
      Entries: `Entradas`,
      Equipment: `Equipo`,
      Expired: `Caducado`,
      Filled: `Lleno`,
      'Incident Report': `Reporte de Incidente`,
      'Select Name': `Seleccionar Nombre`,
      'This incident report could not be found': `Este informe de incidente no se pudo encontrar`,
      'Incident Reports': `Informes de Incidentes`,
      'Date Reported From': `Fecha Reportada desde`,
      'Date Reported To': `Fecha reportada a`,
      'Date Reported': `Fecha Reportada`,
      Owners: `Dueños`,
      Owner: `Dueño`,
      Supervisors: `Supervisores`,
      Supervisor: `Supervisor`,
      'Injury Area': `Área de la Lesión`,
      'Injury Areas': `Áreas de Lesión`,
      'Injury Type': `Tipo de Lesión`,
      'Injury Types': `Tipos de Lesiones`,
      'Root Cause': `Causa principal`,
      'Root Causes': `Causas fundamentales`,
      'Incident Report Classification': `Clasificación de Informes de Incident`,
      'Incident Report Classifications': `Clasificación de Informes de Incidentes`,
      'Incident Report Status': `Estado del Informe de Incidentes`,
      'Incident Report Statuses': `Estados de Informes de Incidentes`,
      Filters: `Filtros`,
      Form: `Formulario`,
      Forms: `Formularios`,
      Fri: `Viernes`,
      Friday: `Viernes`,
      Help: `Ayuda`,
      High: `Alto`,
      Inbox: `Bandeja de Entrada`,
      Includes: `Incluye`,
      Language: `Idioma`,
      'Error Info': `Info de error`,
      'Report Bug': `Reportar un Error`,
      Lesson: `Lección`,
      Lessons: `Lecciones`,
      List: `Lista`,
      Logout: `Cerrar sesión`,
      Low: `Bajo`,
      Manufacturer: `Fabricante`,
      'Model Number': `Número de Modelo`,
      'Inspection Info': `Información de Inspección`,
      'Basic Info': `Información Básica`,
      Manufacturers: `Fabricantes`,
      Medium: `Medio`,
      Missed: `Omitido`,
      Mon: `Lunes`,
      Monday: `Lunes`,
      noFileAttachmentDownload: `Lo siento. Este archivo adjunto no se puede descargar en este momento.`,
      Notes: `Notas`,
      Off: `Apagada`,
      offline1: `El modo sin conexión permite a los usuarios guardar recopilaciones de datos seleccionadas en su dispositivo. Estos datos se utilizarán para completar previamente los campos del formulario, permitir el filtrado de la recopilación de datos y la visualización de conjuntos completos de recopilación de datos.`,
      offline2: `Todos los datos se eliminan de su dispositivo cuando cierra la sesión, por razones de seguridad.`,
      offline3: `Cualquier colección no seleccionada se eliminará del almacenamiento de su dispositivo al sincronizarse.`,
      offline4: `Seleccione los datos deseados que desea guardar en su dispositivo.`,
      On: `Encendida`,
      Open: `Abierto`,
      Partial: `Parcial`,
      Photo: `Foto`,
      Photos: `Fotos`,
      Preferences: `Preferencias`,
      Project: `Proyecto`,
      Projects: `Proyectos`,
      Provider: `Proveedor`,
      Providers: `Proveedores`,
      Refresh: `Actualizar`,
      Reports: `Informes`,
      sdsDisabled1: `Acceso a nuestro motor de Fichas de Datos de Seguridad (ver ejemplo a continuación)`,
      sdsDisabled2: `Realice fácilmente un seguimiento de las SDS relevantes y póngalas a disposición de todos los trabajadores remotos`,
      sdsDisabled3: `Personalice la base de datos según el fabricante específico de su empresa`,
      sdsSearchPlaceholder: `Buscar por: título, fabricante, código de producto`,
      Search: `Buscar`,
      Settings: `Ajustes`,
      Severity: `Gravedad`,
      Share: `Cuota`,
      Sounds: `Sonidos`,
      Status: `Estado`,
      Tag: `Etiqueta`,
      Tags: `Etiquetas`,
      Title: `Título`,
      Description: `Descripción`,
      'File Attachment': `Archivo Adjunto`,
      uploadCancelled: `Carga de archivo CSV cancelada`,
      uploadComplete: `Archivo CSV cargado y enviado al personal de soporte de SafetyHQ.`,
      uploadError: `¡Error al Subir! Ver informe en Configuración`,
      Username: `Nombre de Usuario`,
      Valid: `Válido`,
      Version: `Versión`,
      Wed: `Miércoles`,
      Wednesday: `Miércoles`,
      Worker: `Trabajador`,
      Workers: `Trabajadores`,
      'Cannot be submitted offline': `No se puede enviar sin conexión`,
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: `en`, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
